import('../sass/project.scss');
// everything needed for bootstrap accordion
import('bootstrap/js/dist/collapse');
import('bootstrap/js/dist/carousel');
import('bootstrap/js/dist/modal');
import('bootstrap/js/dist/tooltip');
import('bootstrap/js/dist/popover');
import('bootstrap/js/dist/tab');


document.addEventListener("DOMContentLoaded", function() {
    var toggler = document.getElementById("navbarToggler");
    var content = document.getElementById("navbarContent");

    toggler.addEventListener("click", function() {
        content.classList.toggle("show");
    });
});

document.querySelectorAll('.toggle-submenu').forEach(button => {
    button.addEventListener('click', function () {
        const submenu = this.nextElementSibling;
        submenu.classList.toggle('show');
    });
});
